<template>
  <div></div>
</template>

<script>
import { confirmRegistry } from "@/services/external/users-service";

export default {
  name: "ConfirmRegistry",
  data: () => ({
    message:
      "Seu registro com esse e-mail foi confirmado com sucesso!Tudo pronto, só falta você fazer o login!",
  }),
  created() {
    this.confirmRegistryWithToken();
  },
  methods: {
    async confirmRegistryWithToken() {
      try {
        await confirmRegistry(this.$route.query.t);
        this.$successAlert({
          message:
            "Registro confirmado com sucesso!\nAgora só falta você fazer seu login.",
        });
      } catch (e) {
        this.$warningAlert({
          message: e.response.data.message,
        });
      } finally {
        this.$router.push({ name: "auth.login" });
      }
    },
  },
};
</script>

<style scoped></style>
